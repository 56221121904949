import { ArrowLeft, ArrowRight } from "lucide-react";
import React, { useState, useEffect } from "react";

function ImageViewer({ showImage, startImgIndex, imageUrls, onClose }) {
  const [currentIndex, setCurrentIndex] = useState(startImgIndex);

  const previousButtonClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length
    );
  };

  const nextButtonClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  

  function extractInfoFromURL(url) {
    var parts = url.split("/");
    var filename = parts[parts.length - 1];
    var filenameParts = filename.split("-");

    var time = filenameParts[0];

    var name = filenameParts[2];

    return {
      time: time,
      name: name,
    };
  }

  useEffect(() => {
    setCurrentIndex(startImgIndex);

    const handleKeyPress = (event) => {
      if (event.key === "ArrowLeft" && showImage) {
        previousButtonClick();
      } else if (event.key === "ArrowRight" && showImage) {
        nextButtonClick();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [showImage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedElement = event.target;
      const clickedDiv = clickedElement.classList.contains("outside");
      if (clickedDiv) {
        onClose();
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {showImage && (
        <div className="outside fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-white bg-opacity-80 z-50">
          <div className="relative rounded-xl">
            <div
              onClick={previousButtonClick}
              className="absolute z-10 top-0 left-2 h-full rounded-l-xl flex items-center hover:bg-white hover:bg-opacity-80 w-min transition-all duration-200"
            >
              <ArrowLeft />
            </div>

            <div className="bg-white p-2 relative ">
              <div className="w-[80vw] md:w-auto md:h-[70vh] flex items-center 3xl:h-[47.125rem]">
                <img
                  src={imageUrls[currentIndex]}
                  alt=""
                  className="h-full rounded-xl object-cover"
                />
              </div>
              <p className="font-della text-xl text-center pt-2">
                {extractInfoFromURL(imageUrls[currentIndex]).name}
              </p>
              <p className="font-della text-md text-center absolute top-2 right-2 pr-2 bg-elegance text-white rounded-tr-xl">
                {extractInfoFromURL(imageUrls[currentIndex]).time}
              </p>
            </div>

            <div
              onClick={nextButtonClick}
              className="absolute h-full top-0 rounded-r-xl right-2 flex items-center hover:bg-white hover:bg-opacity-80 w-min transition-all duration-200"
            >
              <ArrowRight />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ImageViewer;
