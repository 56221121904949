import React, { useState } from "react";

function PasswordInput({ onSubmit }) {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(password);
  };

  return (
    <div className="h-screen w-full flex flex-col gap-4 items-center justify-center">
      <input
        type="password"
        placeholder="Unesite lozinku"
        value={password}
        className="p-2 w-full rounded-md text-elegance text-center"
        onChange={handlePasswordChange}
      />
      <button className="w-full p-2  rounded-md border border-white text-white " onClick={handleSubmit}>Pristupi</button>
    </div>
  );
}

export default PasswordInput;
