import React, { useState, useEffect } from "react";
import { BarChart3, X } from "lucide-react";

function StatisticModal({ imageUrls }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [usersLenght, setusersLenght] = useState(0);

  const extractUniqueId = (url) => {
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    const idPart = filename.split("-")[1];
    return idPart;
  };

  useEffect(() => {
    setusersLenght(
      Array.from(new Set(imageUrls.map((url) => extractUniqueId(url)))).length
    );
  }, [imageUrls]);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="z-20 ">
      <div className="absolute top-4 right-4 z-50">
        <button onClick={openModal}>
          <BarChart3 className="text-white" />
        </button>
      </div>




      <div 
        onClick={closeModal} className={`z-10 top-0 left-0 absolute w-screen h-screen flex items-center justify-center  ${modalOpen ? " flex" : "hidden"}`}>
     
       
        <div className="bg-white text-gray-800 p-8 relative rounded shadow-lg max-w-md flex flex-col gap-6 ">
          <button className="absolute right-4 top-4">
            <X className=" text-gray-800" />
          </button>
          <h2 className="text-xl font-semibold">
            Prijavljni gosti: {usersLenght}
          </h2>
          <h2 className="text-xl font-semibold">
            Objavljene fotografije: {imageUrls.length}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default StatisticModal;
